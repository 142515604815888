<template>
    <div>
      <h4>Type de produit</h4>
      
      <data-table
        :data-source="filtres"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-filtre"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
      <modal
        title="Ajout de filtre"
        id="add-filtre"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="addingFiltre"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-libelle">Libelle</label>
                    <input
                        type="text"
                        id="add-libelle"
                        name="add-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-type">Type de produit</label>
                    <select name="add-type" id="add-type" v-model="type" class="form-control" v-select="{placeholder: 'Selectionnez le type de produit'}">
                        <option value=""></option>
                        <option v-for="(t, index) in types" :value="t.uid" :key="index">{{ t.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </modal>
      <modal
        title="Modification du filtre"
        id="update-filtre"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editingFiltre"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-libelle">Libelle</label>
                    <input
                        type="text"
                        id="edit-libelle"
                        name="edit-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-type">Type de produit</label>
                    <select name="edit-type" id="edit-type" v-model="type" class="form-control" v-select="{placeholder: 'Selectionnez le type de produit'}">
                        <option value=""></option>
                        <option v-for="(t, index) in types" :value="t.uid" :key="index">{{ t.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import { ADD_FILTRE,  UPDATE_FILTRE } from '../../../graphql/market'
  const Actions = () => import('../../../components/admin/market/filtreActions.vue')
  export default {
      components: { DataTable, Modal},
      data(){
          return {
              libelle: null,
              type: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.type = this.selectedObject.type
                  }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.type = null
          },
          addingFiltre(){
              
              this.$apollo.mutate({
                  mutation: ADD_FILTRE,
                  variables: {
                      "libelle": this.libelle,
                      "type": this.type
                  },
                  update: ({data}) => {
                      console.log(`Filtre add successfully on uid ${data.addMarketFiltre}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editingFiltre(){
              this.$apollo.mutate({
                  mutation: UPDATE_FILTRE,
                  variables: {
                      "libelle": this.libelle,
                      "type": this.type,
                      "uid": this.selectedObject.uid
                  },
                  update: () => {
                      console.log(`Type product  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              filtres: 'market/filtres',
              types: 'market/typeProducts',
              selectedObject: 'selectedObject'
          }),
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Type de produit', name: 'type', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>